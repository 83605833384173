import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import GridFeatureManager from '../feature/GridFeatureManager.js';
/**
 * @module Grid/feature/Stripe
 */
/**
 * Stripes rows by adding alternating CSS classes to all row elements (`b-even` and `b-odd`).
 *
 * This feature is <strong>disabled</strong> by default.
 *
 * ```javascript
 * let grid = new Grid({
 *   features: {
 *     stripe: true
 *   }
 * });
 *
 * {@inlineexample Grid/feature/Stripe.js}
 *
 * @demo Grid/columns
 *
 * @extends Core/mixin/InstancePlugin
 * @classtype stripe
 * @feature
 */
export default class Stripe extends InstancePlugin {
    static $name = 'Stripe';
    static get pluginConfig() {
        return {
            chain : [
                'beforeRenderRow'
            ]
        };
    }
    doDisable(disable) {
        if (!this.isConfiguring) {
            // Refresh rows to add/remove even/odd classes
            this.client.refreshRows();
        }
        super.doDisable(disable);
    }
    /**
     * Applies even/odd CSS when row is rendered
     * @param {Grid.row.Row} rowModel
     * @private
     */
    beforeRenderRow({ row, record }) {
        const
            { enabled }      = this,
            { isGroupHeader } = record,
            groupParent       = this.client.store.isGrouped && record.instanceMeta(this.client.store).groupParent,
            even              = (groupParent?.groupChildren?.indexOf(record) ?? row.dataIndex) % 2 === 0;
        row.assignCls({
            'b-even' : enabled && !isGroupHeader && even,
            'b-odd'  : enabled && !isGroupHeader && !even
        });
    }
}
Stripe._$name = 'Stripe'; GridFeatureManager.registerFeature(Stripe);
