import Container from './Panel.js';
import DateHelper from '../helper/DateHelper.js';
/**
 * @module Core/widget/TimePicker
 */
const
    hours   = Array.from(Array(24)).map((_, i) => {
        return { id : i, text : String(i + 100).slice(1) };
    }),
    seconds = Array.from(Array(60)).map((_, i) => {
        return { id : i, text : String(i + 100).slice(1) };
    });
/**
 * A Container which displays hour and minute (and optionally second) lists to pick values from.
 *
 * ```javascript
 * new TimeField({
 *     label     : 'Time field',
 *     appendTo  : document.body,
 *     // Configure the time picker
 *     picker    : {
 *         cls : 'my-picker.class
 *     }
 * });
 * ```
 *
 * ## Contained widgets
 *
 * The default widgets contained in this picker are:
 *
 * | Widget ref | Type                     | Description                             |
 * |------------|--------------------------|-----------------------------------------|
 * | `hour`     | {@link Core.widget.List} | The hour picker                         |
 * | `minute`   | {@link Core.widget.List} | The minute picker                       |
 * | `second`   | {@link Core.widget.List} | The second picker                       |
 *
 * This class is not intended for use in applications. It is used internally by the {@link Core.widget.TimeField} class.
 *
 * @extends Core/widget/Container
 * @classtype timepicker
 * @widget
 */
export default class TimePicker extends Container {
    //region Config
    static $name = 'TimePicker';
    static type = 'timepicker';
    static configurable = {
        floating : true,
        layout   : 'hbox',
        defaults : {
            type         : 'list',
            displayField : 'id',
            itemTpl(r) {
                const
                    value      = r.id,
                    displayVal = this.ref !== 'hour' || this.owner.is24Hour || value ? value : 12;
                return String(displayVal + 100).slice(1);
            },
            itemCls        : 'b-timepicker-item',
            itemsFocusable : false,
            scrollable     : {
                overflowY : 'hidden-scroll'
            },
            onSelectionChange : 'up.onSelectionChange',
            navigator         : {
                activateOnMouseover : true
            }
        },
        items : {
            hour : {
                items : hours
            },
            minute : {
                items : []
            },
            second : {
                hidden : true,
                items  : seconds
            }
        },
        step : {
            magnitude : 5,
            unit      : 'm'
        },
        autoShow : false,
        trapFocus : true,
        /**
         * Time value, which can be a Date or a string. If a string is specified, it will be converted using the
         * specified {@link #config-format}
         * @prp {Date}
         * @accepts {Date|String}
         */
        value : {
            $config : {
                equal : 'date'
            },
            value : null
        },
        /**
         * Time format. Used to set appropriate 12/24 hour format to display.
         * See {@link Core.helper.DateHelper#function-format-static DateHelper} for formatting options.
         * @prp {String}
         */
        format : null,
        /**
         * Max value, which can be a Date or a string. If a string is specified, it will be converted using the
         * specified {@link #config-format}
         * @prp {Date}
         * @accepts {Date|String}
         */
        max : null,
        /**
         * Min value, which can be a Date or a string. If a string is specified, it will be converted using the
         * specified {@link #config-format}
         * @prp {Date}
         * @accepts {Date|String}
         */
        min : null,
        is24Hour : {
            $config : 'lazy',
            value   : null
        },
        /**
         * Initial value, which can be a Date or a string. If a string is specified, it will be converted using the
         * specified {@link #config-format}. Initial value is restored on Escape click
         * @member {Date} initialValue
         * @accepts {Date|String}
         */
        initialValue : null // Not documented as config on purpose, API was that way
    };
    //endregion
    alignTo() {
        const
            { widgetMap }      = this,
            { minute, second } = this.owner.input;
        this.getConfig('is24Hour');
        // The step count defines the number of minute blocks.
        // Shrink total height to match that if wanted.
        if (this.minimizeHeight) {
            widgetMap.hour.maxHeight = widgetMap.second.maxHeight = widgetMap.minute.height;
        }
        widgetMap.minute[minute ? 'show' : 'hide']();
        widgetMap.second[second ? 'show' : 'hide']();
        return super.alignTo(...arguments);
    }
    updateIs24Hour(is24Hour) {
        this.widgetMap.hour.store.filter(t => is24Hour ? true : t.id < 12);
    }
    updateStep(step) {
        step = DateHelper.as('m', step.magnitude, step.unit);
        const minuteValues = [];
        for (let minutes = 0; minutes < 60; minutes += step) {
            minuteValues.push({ id : minutes, text : String(100 + minutes).slice(1) });
        }
        this.widgetMap.minute.store.data = minuteValues;
    }
    //region Event listeners
    onSelectionChange({ source, selected : [selectedItem] }) {
        this._isUserAction = source._isUserAction;
        // Keep UI in sync in case selection was programatic, and not instigated by the UI.
        source.navigator.activeItem = selectedItem;
        // Update our value unless we are here because we are updating the value.
        if (!this.updatingValue) {
            this.value = this.pickerToTime();
        }
        this._isUserAction = false;
    }
    //endregion
    //region Internal functions
    pickerToTime() {
        const
            me                       = this,
            { hour, minute, second } = me.widgetMap,
            hours                    = hour.selected.first.id,
            newValue                 = new Date(me.value),
            h                        = newValue.getHours(),
            isPM                     = h > 11;
        // Convert 8 to 20 if we are in the PM
        newValue.setHours(hours + ((isPM && hours < 12) ? 12 : 0));
        newValue.setMinutes(minute.selected.first.id);
        if (me.seconds) {
            newValue.setSeconds(second.selected.first.id);
        }
        return newValue;
    }
    triggerTimeChange(time) {
        /**
         * Fires when a time is changed.
         * @event timeChange
         * @param {Date} time The selected time.
         */
        this.trigger('timeChange', { time });
    }
    //endregion
    //region Getters / Setters
    updateInitialValue(initialValue) {
        this.value = initialValue;
    }
    changeValue(value) {
        if (value) {
            value = typeof value === 'string' ? DateHelper.parse(value, this.format) : value;
        }
        return value ?? DateHelper.getTime(0);
    }
    updateValue(value) {
        const
            me      = this,
            {
                hour,
                minute,
                second
            }       = me.widgetMap,
            h       = value.getHours(),
            hours   = me.is24Hour ? h : h % 12,
            minutes = value.getMinutes(),
            seconds = value.getSeconds();
        me.updatingValue = true;
        // We wrap the value in a Number object so that it is used as the ID of the
        // record to select, not the index. This is because of filtering.
        hour.navigator.activeItem = new Number(hours);
        hour.select(hours, true);
        minute.navigator.activeItem = new Number(minutes);
        minute.select(minutes, true);
        second.navigator.activeItem = new Number(seconds);
        second.select(seconds, true);
        me.updatingValue = false;
        if (me.isVisible) {
            me.triggerTimeChange(value);
        }
    }
    changeMin(min) {
        return typeof min === 'string' ? DateHelper.parse(min, this.format) : min;
    }
    updateMin(min) {
        const
            {
                hour,
                minute,
                second
            }                   = this.widgetMap,
            { owner, is24Hour } = this;
        if (min) {
            const
                minHours   = min.getHours(),
                minMinutes = min.getMinutes(),
                minSeconds = min.getSeconds();
            hour.store.filter({
                id       : 'min-filter',            
                filterBy : ({ id }) => {
                    // Handle minHour:9, but if the field 12 hour and is in the PM,
                    // then 0 to 11 means 12 to 23.
                    if (!is24Hour && owner.value.getHours() > 12) {
                        id += 12;
                    }
                    return id >= minHours;
                }
            });
            minute.store.filter({
                id       : 'min-filter',            
                filterBy : ({ id }) => id >= minMinutes
            });
            second.store.filter({
                id       : 'min-filter',            
                filterBy : ({ id }) => id >= minSeconds
            });
        }
        else {
            hour.store.removeFilter('min-filter');
            minute.store.removeFilter('min-filter');
            second.store.removeFilter('min-filter');
        }
    }
    changeMax(max) {
        return typeof max === 'string' ? DateHelper.parse(max, this.format) : max;
    }
    updateMax(max) {
        const
            {
                hour,
                minute,
                second
            } = this.widgetMap;
        if (max) {
            const
                minHours   = max.getHours(),
                minMinutes = max.getMinutes(),
                minSeconds = max.getSeconds();
            hour.store.filter({
                id       : 'max-filter',            
                filterBy : ({ id }) => id <= minHours
            });
            minute.store.filter({
                id       : 'max-filter',            
                filterBy : ({ id }) => id <= minMinutes
            });
            second.store.filter({
                id       : 'max-filter',            
                filterBy : ({ id }) => id <= minSeconds
            });
        }
        else {
            hour.store.removeFilter('max-filter');
            minute.store.removeFilter('max-filter');
            second.store.removeFilter('max-filter');
        }
    }
    //endregion
}
// Register this widget type with its Factory
TimePicker.initClass();
TimePicker._$name = 'TimePicker';