import Column from '../../Grid/column/Column.js';
import ColumnStore from '../../Grid/data/ColumnStore.js';
import Scale from '../../Core/widget/graph/Scale.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
/**
 * @module Scheduler/column/ScaleColumn
 */
/**
 * An object representing a point on the scale displayed by {@link Scheduler/column/ScaleColumn}.
 *
 * @typedef {Object} ScalePoint
 * @property {Number} value Scale point value
 * @property {String} [text] Scale point label (if not provided the point won't display a label)
 * @property {String} [unit] Scale point value unit. This is used by the resource histogram and
 * resource utilization views to match histogram bar values expressed in milliseconds to the scale.
 * **Please note** The views read a scale unit to use from the last scale point of the provided `scalePoints` array.
 * All points in the array are meant to use the same unit value.
 */
/**
 * A specialized column showing a graduated scale from a defined array of values
 * and labels. This column is used in the {@link Scheduler/view/TimelineHistogram} and is not editable. Normally
 * you should not need to interact with this class directly.
 *
 * @extends Grid/column/Column
 * @classtype scale
 * @column
 */
export default class ScaleColumn extends Column {
    //region Config
    static $name = 'ScaleColumn';
    static type = 'scale';
    static isScaleColumn = true;
    static fields = [
        /**
         * Array of objects representing scale points. If not provided the column will try
         * reading points from the displayed record {@link #config-field} (`scalePoints` field by default).
         *
         * ```javascript
         * new TimelineHistogram({
         *     columns : {
         *         type        : 'scale',
         *         scalePoints : [
         *             { text : '8h', value : 8 },
         *             { text : '16h', value : 16 },
         *             { text : '24h', value : 24 }
         *         ]
         *     }
         * })
         * ```
         * @config {ScalePoint[]} scalePoints
         */
        'scalePoints'
    ];
    static defaults = {
        text            : '\xa0',
        width           : 40,
        minWidth        : 40,
        /**
         * The {@link Core/data/field/DataField#config-name} of the {@link Core/data/Model data model} field to
         * read scale point values from.
         *
         * @prp {String} field
         * @readonly
         * @category Common
         * @default "scalePoints"
         */
        field           : 'scalePoints',
        cellCls         : 'b-scale-cell',
        editor          : false,
        sortable        : false,
        groupable       : false,
        filterable      : false,
        alwaysClearCell : false,
        scalePoints     : null
    };
    //endregion
    //region Constructor/Destructor
    onDestroy() {
        this.scaleWidget.destroy();
    }
    //endregion
    //region Internal
    set width(width) {
        super.width = width;
        this.scaleWidget.width = width;
    }
    get width() {
        return super.width;
    }
    applyValue(useProp, key, value) {
        // pass value to scaleWidget
        if (key === 'scalePoints') {
            this.scaleWidget[key] = value;
        }
        return super.applyValue(...arguments);
    }
    buildScaleWidget() {
        const me = this;
        const scaleWidget = new Scale({
            owner         : me.grid,
            appendTo      : me.grid.floatRoot,
            cls           : 'b-hide-offscreen',
            align         : 'right',
            scalePoints   : me.scalePoints,
            monitorResize : false
        });
        Object.defineProperties(scaleWidget, {
            width : {
                get() {
                    return me.width;
                },
                set(width) {
                    this.element.style.width = `${width}px`;
                    this._width = me.width;
                }
            },
            height : {
                get() {
                    return this._height;
                },
                set(height) {
                    this.element.style.height = `${height}px`;
                    this._height = height;
                }
            }
        });
        scaleWidget.width = me.width;
        return scaleWidget;
    }
    get scaleWidget() {
        const me = this;
        if (!me._scaleWidget) {
            me._scaleWidget = me.buildScaleWidget();
        }
        return me._scaleWidget;
    }
    //endregion
    //region Render
    defaultRenderer({ cellElement, value, scaleWidgetConfig, scaleWidget = this.scaleWidget }) {
        ObjectHelper.assign(scaleWidget, {
            scalePoints : value || this.scalePoints,
            height      : this.grid.rowHeight
        }, scaleWidgetConfig);
        scaleWidget.refresh();
        // Clone the scale widget element since every row is supposed to have
        // the same scale settings
        const scaleCloneElement = scaleWidget.element.cloneNode(true);
        scaleCloneElement.removeAttribute('id');
        scaleCloneElement.classList.remove('b-hide-offscreen');
        cellElement.innerHTML = '';
        cellElement.appendChild(scaleCloneElement);
    }
    //endregion
}
ColumnStore.registerColumnType(ScaleColumn);
ScaleColumn._$name = 'ScaleColumn';