import ExporterBase from '../../../../Grid/feature/export/exporter/ExporterBase.js';
/**
 * @module Scheduler/feature/export/verticalexporter/VerticalExporter
 */
/**
 * Base class for all vertical exporters
 * @extends Grid/feature/export/exporter/ExporterBase
 */
export default class VerticalExporter extends ExporterBase {
    static isHorizontal = false;
    cloneElement(element, target = this.element, clear = true) {
        super.cloneElement(element, target, clear);
    }
    async prepareComponent(config) {
        await super.prepareComponent(config);
        const
            {
                exportMeta,
                element
            }                   = this,
            { resourceColumns } = config.client.timeAxisColumn;
        exportMeta.resourceHeaderPlaceholder = this.createPlaceholder(element.querySelector(`#${resourceColumns.id}`));
        exportMeta.resourceHeaders = '';
    }
    async onRowsCollected() {}
}
VerticalExporter._$name = 'VerticalExporter';