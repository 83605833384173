import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
/**
 * @module Scheduler/feature/ScrollButtons
 */
/**
 * This feature injects buttons to the beginning and end of each row that will scroll to the next or previous event
 * outside the current view. It can optionally show a label along with the button, using the
 * {@link #config-labelRenderer}. Only supported in horizontal {@link Scheduler.view.SchedulerBase#config-mode}
 *
 * ```javascript
 * new Scheduler({
 *     appendTo : 'container',
 *     features : {
 *         scrollButtons : {
 *             labelRenderer({ resourceRecord, isBefore, nbrEvents }) {
 *                 return `${nbrEvents} events`;
 *             }
 *         }
 *     }
 * ```
 *
 * {@inlineexample Scheduler/feature/ScrollButtons.js}
 *
 * This feature is **disabled** by default.
 *
 * @extends Core/mixin/InstancePlugin
 * @demo Scheduler/scroll-buttons
 * @classtype scrollButtons
 * @feature
 */
export default class ScrollButtons extends InstancePlugin {
    //region Config
    static $name = 'ScrollButtons';
    // Default configuration.
    static configurable = {
        /**
         * The icon to use for the button scrolling into the past
         * @config {String}
         * @default
         */
        backwardIconCls : 'b-icon-before',
        /**
         * The icon to use for the button scrolling into the future
         * @config {String}
         * @default
         */
        forwardIconCls : 'b-icon-after',
        /**
         * A method letting you render a label inside the button.
         *
         * {@note}When returning content, be sure to consider how that content should be encoded to avoid XSS
         * (Cross-Site Scripting) attacks. This is especially important when including user-controlled data such as
         * the event's `name`. The function {@link Core.helper.StringHelper#function-encodeHtml-static} as well as
         * {@link Core.helper.StringHelper#function-xss-static} can be helpful in these cases.{/@note}
         *
         * @config {Function} labelRenderer
         * @param {Object} detail An object that contains data about the event being rendered.
         * @param {Scheduler.model.ResourceModel} detail.resourceRecord The resource record
         * @param {Boolean} detail.isBefore `true` if the label being rendered is for the arrow scrolling into the past
         * @param {Number} detail.nbrEvents The number of events that can be scrolled to
         * @returns {String} The text or HTML
         */
        labelRenderer : null,
        /**
         * A config object describing how the scroll action should be performed.
         * @config {BryntumScrollOptions}
         */
        scrollOptions : { animate : { duration : 600, easing : 'easeTo' }, y : false, edgeOffset : 50 },
        testConfig : {
            scrollOptions : { animate : { duration : 20, easing : 'easeTo' }, y : false, edgeOffset : 50 }
        }
    };
    static get pluginConfig() {
        return {
            chain : [
                'onPaint',
                'onElementClick'
            ]
        };
    }
    //endregion
    doDisable(disable) {
        this.client.refresh();
        super.doDisable(disable);
    }
    onPaint({ firstPaint }) {
        if (firstPaint) {
            const
                { client, renderer } = this,
                { timeAxisColumn }   = client,
                { defaultRenderer }  = timeAxisColumn;
            client.timeAxisSubGrid.scrollable.ion({
                scrollEnd() {
                    client.refreshColumn(timeAxisColumn);
                }
            });
            timeAxisColumn.defaultRenderer = (...args) => {
                defaultRenderer.apply(timeAxisColumn, args);
                return renderer.apply(this, args);
            };
        }
    }
    renderer({ record : resourceRecord, grid }) {
        const
            { visibleDateRange }                               = grid,
            { labelRenderer, forwardIconCls, backwardIconCls } = this;
        let nbrEventsAfter  = 0,
            nbrEventsBefore = 0;
        resourceRecord.events.forEach(({ isScheduled, startDate, endDate })  => {
            if (!isScheduled) {
                return;
            }
            if (endDate <= visibleDateRange.startDate) {
                nbrEventsBefore++;
            }
            else if (startDate >= visibleDateRange.endDate) {
                nbrEventsAfter++;
            }
        });
        return {
            class    : 'b-scroll-buttons-container',
            children : [{
                // We always render this button but make it invisible if there are no events before so the positioning
                // of b-scroll-forward button remains same
                class    : `b-scroll-button-wrap b-scroll-backward ${nbrEventsBefore > 0 ? '' : 'b-scroll-button-hidden'}`,
                children : [
                    {
                        tag   : 'i',
                        class : {
                            'b-icon'          : 1,
                            'b-scroll-button' : 1,
                            [backwardIconCls] : 1
                        }
                    },
                    labelRenderer && {
                        tag   : 'span',
                        class : 'b-scroll-button-content',
                        text  : labelRenderer({ resourceRecord, isBefore : true, nbrEvents : nbrEventsBefore })
                    }
                ]
            },
            nbrEventsAfter > 0 ? {
                class    : 'b-scroll-button-wrap b-scroll-forward',
                children : [
                    labelRenderer && {
                        tag   : 'span',
                        class : 'b-scroll-button-content',
                        text  : labelRenderer({ resourceRecord, isBefore : false, nbrEvents : nbrEventsAfter })
                    },
                    {
                        tag   : 'i',
                        class : {
                            'b-icon'          : 1,
                            'b-scroll-button' : 1,
                            [forwardIconCls]  : 1
                        }
                    }
                ]
            } : null
            ]
        };
    }
    onElementClick(event) {
        const
            { client } = this,
            { target } = event;
        /**
         * Fires on owner when the scroll button is clicked, return `false` to prevent default scroll behavior
         * @event scrollButtonClick
         * @preventable
         * @param {Event} domEvent DOM event
         * @on-owner
         */
        if (target.closest('.b-scroll-button-wrap') && client.trigger('scrollButtonClick', { domEvent : event }) !== false) {
            const
                { visibleDateRange } = client,
                record               = client.getRecordFromElement(target),
                isForward            = target.closest('.b-scroll-forward');
            if (isForward) {
                client.scrollResourceEventIntoView(record, record.getEventStartingAfter(visibleDateRange.endDate), ObjectHelper.assign({ block : 'end' }, this.scrollOptions));
            }
            else {
                client.scrollResourceEventIntoView(record, record.getEventEndingBefore(visibleDateRange.startDate), ObjectHelper.assign({ block : 'start' }, this.scrollOptions));
            }
        }
    }
}
ScrollButtons._$name = 'ScrollButtons'; GridFeatureManager.registerFeature(ScrollButtons, false, 'Scheduler');
