import InstancePlugin from '../../../Core/mixin/InstancePlugin.js';
import TimeZoneHelper from '../../../Core/helper/TimeZoneHelper.js';
/**
 * @module Scheduler/data/plugin/ProjectModelMixin
 */
/**
 * Plugs in to a time zone convertable Store to affect and add some functionality.
 * @plugin
 * @private
 */
export default class StoreTimeZonePlugin extends InstancePlugin {
    static $name = 'storeTimeZonePlugin';
    static get pluginConfig() {
        return {
            before   : ['processRecord'],
            assign   : ['beforeSyncRecord', 'afterSyncRecord'],
            override : ['getOccurrencesForTimeSpan']
        };
    }
    get timeZone() {
        return this.client.project.timeZone;
    }
    // Overrides a Store's processRecord function to be able to convert records added by a dataset
    // before they are processed by the engine
    processRecord(record, isDataSet) {
        if (isDataSet || this.client.isLoadingData || record.timeZone !== undefined) {
            // When loading or changing dataset, de data is treated as local dates and need to be converted
            // Also convert when adding a record with a timeZone specified (null means local time zone)
            this.convertRecordToTimeZone(record);
        }
    }
    convertRecordToTimeZone(record, timeZone = this.timeZone) {
        if (record.timeZone !== timeZone) {
            record.$ignoreChange = true; // Used to ignore changes in NestedEvents feature
            // Convert baselines if exists
            if (record.baselines?.count) {
                for (const bl of record.baselines) {
                    // The baseline records is not marked with a timezone when they are created
                    if (record.timeZone !== bl.timeZone) {
                        bl.timeZone = record.timeZone;
                    }
                    bl.convertToTimeZone(timeZone);
                }
            }
            if (record.isRecurring) {
                // occurrencies will be re-built with proper dates
                record.removeOccurrences();
            }
            record.convertToTimeZone(timeZone);
            record.$ignoreChange = false;
        }
    }
    beforeSyncRecord({ record }) {
        if (record.timeZone != null) {
            record.$restoreTimeZone = record.timeZone;
            record.convertToTimeZone();
        }
    }
    afterSyncRecord({ record }) {
        if (record.$restoreTimeZone) {
            record.convertToTimeZone(record.$restoreTimeZone);
            record.$restoreTimeZone = null;
        }
    }
    getOccurrencesForTimeSpan(timeSpan, start, end) {
        let result = [];
        if (timeSpan.isRecurring) {
            const
                { timeZone }     = timeSpan.recurrence,
                timeSpanTimeZone = timeSpan.timeZone;
            let originalStartDate;
            // If the recurrence is configured with a timeZone, we should perform our calculations in that timeZone
            // Ignore it if the current timeZone is same as the configured, or if the timeSpan has not been converted
            // yet
            if (timeZone != null && timeSpanTimeZone != timeZone && (!timeSpan.project || timeSpanTimeZone == timeSpan.project.timeZone)) {
                originalStartDate = timeSpan.startDate;
                // Super silently convert the timeSpan's startDate to the configured timeZone
                // Can't find a way that works for both recurring events and resourectimeranges
                const tempStartDate = TimeZoneHelper.toTimeZone(timeSpan.getLocalDate('startDate'), timeZone);
                if (timeSpan.isResourceTimeRange) {
                    timeSpan.setData('startDate', tempStartDate);
                }
                else {
                    timeSpan._startDate = tempStartDate;
                }
            }
            result = this.overridden.getOccurrencesForTimeSpan(timeSpan, start, end);
            // We entered the if statement above
            if (originalStartDate != null) {
                // Restore the timeSpan's startDate, super silently
                if (timeSpan.isResourceTimeRange) {
                    timeSpan.setData('startDate', originalStartDate);
                }
                else {
                    timeSpan._startDate = originalStartDate;
                }
                result.forEach(occurrence => {
                    if (occurrence !== timeSpan) {
                        // Convert the occurrence startDate and endDate super silently
                        let startDate = TimeZoneHelper.fromTimeZone(occurrence.startDate, timeZone),
                            endDate = TimeZoneHelper.fromTimeZone(occurrence.endDate, timeZone);
                        if (timeSpanTimeZone != null) {
                            startDate = TimeZoneHelper.toTimeZone(startDate, timeSpanTimeZone);
                            endDate = TimeZoneHelper.toTimeZone(endDate, timeSpanTimeZone);
                        }
                        occurrence.setData({
                            startDate,
                            endDate
                        });
                    }
                });
            }
        }
        return result;
    }
}
StoreTimeZonePlugin._$name = 'StoreTimeZonePlugin';