import { Orientation, PaperFormat } from '../../../../Grid/feature/export/Utils.js';
import SchedulerExporterMixin from '../exporter/SchedulerExporterMixin.js';
import VerticalExporter from './VerticalExporter.js';
/**
 * @module Scheduler/feature/export/verticalexporter/SinglePageExporterVertical
 */
/**
 * A single page exporter for vertical mode. Used by the {@link Scheduler.feature.export.PdfExport} feature to export to
 * single page. Content will be scaled in both directions to fit the page.
 *
 * You do not need to use this class directly.
 *
 * ### Extending exporter
 *
 * ```javascript
 * class MySinglePageExporter extends SinglePageExporterVertical {
 *     // type is required for exporter
 *     static type = 'mysinglepageexporter';
 *
 *     get stylesheets() {
 *         const stylesheets = super.stylesheets;
 *
 *         stylesheets.forEach(styleNodeOrLinkTag => doSmth(styleNodeOrLinkTag))
 *
 *         return stylesheets;
 *     }
 * }
 *
 * const scheduler = new Scheduler({
 *     features : {
 *         pdfExport : {
 *             // this export feature is configured with only one exporter
 *             exporters : [MySinglePageExporter]
 *         }
 *     }
 * });
 *
 * // run export with the new exporter
 * scheduler.features.pdfExport.export({ exporter : 'mysinglepageexporter' });
 * ```
 *
 * @classtype singlepage
 * @extends Scheduler/feature/export/verticalexporter/VerticalExporter
 */
export default class SinglePageExporterVertical extends SchedulerExporterMixin(VerticalExporter) {
    static $name = 'SinglePageExporterVertical';
    static type = 'verticalsinglepage';
    static get title() {
        // In case locale is missing exporter is still distinguishable
        return this.localize('L{SinglePageExporter.singlepage}');
    }
    static configurable = {
        /**
         * Set to `true` to center content horizontally on the page
         * @config {Boolean}
         */
        centerContentHorizontally : false
    };
    async prepareComponent(config) {
        await super.prepareComponent(config);
        Object.assign(this.exportMeta, {
            verticalPages      : 1,
            horizontalPages    : 1,
            totalPages         : 1,
            currentPage        : 0,
            verticalPosition   : 0,
            horizontalPosition : 0
        });
    }
    async renderPage(config) {
        const { totalHeight } = this.exportMeta;
        await this.onRowsCollected([], config);
        // Calculate exact grid height according to the last exported row
        this.exportMeta.exactGridHeight = totalHeight;
        return totalHeight;
    }
    buildPageHtml(config) {
        const { subGrids } = this.exportMeta;
        let html = super.buildPageHtml(config);
        // Now when rows are collected, we need to add them to exported grid
        // let html = me.prepareExportElement();
        Object.values(subGrids).forEach(({ placeHolder }) => {
            const
                placeHolderText = placeHolder.outerHTML,
                contentHtml     = '';
            html = html.replace(placeHolderText, contentHtml);
        });
        return html;
    }
}
// HACK: terser/obfuscator doesn't yet support async generators, when processing code it converts async generator to regular async
// function.
SinglePageExporterVertical.prototype.pagesExtractor = async function * pagesExtractor(config) {
    // When we prepared grid we stretched it horizontally, now we need to gather all rows
    // There are two ways:
    // 1. set component height to scrollable.scrollHeight value to render all rows at once (maybe a bit more complex
    // if rows have variable height)
    // 2. iterate over rows, scrolling new portion into view once in a while
    // #1 sounds simpler, but that might require too much rendering, let's scroll rows instead
    const
        me             = this,
        { client }     = config,
        {
            totalWidth,
            verticalTimeAxisMargin
        } = me.exportMeta,
        styles         = me.stylesheets,
        isPortrait     = config.orientation === Orientation.portrait,
        paperFormat    = PaperFormat[config.paperFormat],
        isPrint        = config.useBrowserPrint,
        paperWidth     = me.getPaperWidth(paperFormat, isPortrait),
        paperHeight    = me.getPaperHeight(paperFormat, isPortrait);
    let totalHeight, header, footer;
    totalHeight = await me.renderPage(config);
    // Measure header and footer height
    if (config.headerTpl) {
        header = me.prepareHTML(config.headerTpl({ totalWidth }));
        const height = me.measureElement(header, 'b-export-header');
        totalHeight += height;
    }
    if (config.footerTpl) {
        footer = me.prepareHTML(config.footerTpl({ totalWidth }));
        const height = me.measureElement(footer, 'b-export-footer');
        totalHeight += height;
    }
    const
        html              = me.buildPageHtml(config),
        totalClientHeight = totalHeight,
        widthScale        = Math.min(1, me.getScaleValue(me.inchToPx(paperWidth), totalWidth)),
        heightScale       = Math.min(1, me.getScaleValue(me.inchToPx(paperHeight), totalHeight)),
        scale             = Math.min(widthScale, heightScale);
    // Now add style to stretch grid vertically
    styles.push(
        `<style>
                #${client.id} {
                    height: ${totalClientHeight}px !important;
                    width: ${totalWidth}px !important;
                }
                .b-verticaltimeaxiscolumn {
                    margin-top: ${verticalTimeAxisMargin}px;
                }
                ${
            isPrint
                ? `
                            html, body {
                                overflow: hidden;
                            }
                            .b-page-wrap {
                                width: ${paperWidth}in;
                                height: ${paperHeight}in;
                            }
                            .b-print:not(.b-firefox) .b-export-content {
                                zoom: ${scale};
                                height: 100%;
                            }
                            .b-print.b-firefox .b-export-content {
                                transform: scale(${scale}) ${me.centerContentHorizontally ? 'translateX(-50%)' : ''};
                                transform-origin: top left;
                                height: ${scale === 1 ? 'inherit' : 'auto !important'};
                            }
                            .b-export-content {
                                ${me.centerContentHorizontally ? 'left: 50%;' : ''}
                            }
                        `
                : `
                            .b-export-content {
                                ${me.centerContentHorizontally ? 'left: 50%;' : ''}
                                transform: scale(${scale}) ${me.centerContentHorizontally ? 'translateX(-50%)' : ''};
                                transform-origin: top left;
                                height: ${scale === 1 ? 'inherit' : 'auto !important'};
                            }
                        `
        }
            </style>`
    );
    // This is a single page exporter, so we only yield one page
    yield {
        html : me.pageTpl({
            html,
            header,
            footer,
            styles,
            paperWidth,
            paperHeight,
            currentPage : 0,
            isPrint     : config.useBrowserPrint
        })
    };
};
SinglePageExporterVertical._$name = 'SinglePageExporterVertical';