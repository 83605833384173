import DateHelper from '../../../../Core/helper/DateHelper.js';
import MultiPageVerticalExporter from '../exporter/MultiPageVerticalExporter.js';
import SchedulerExporterMixin from '../exporter/SchedulerExporterMixin.js';
import VerticalExporter from './VerticalExporter.js';
/**
 * @module Scheduler/feature/export/verticalexporter/MultiPageVerticalExporterVertical
 */
/**
 * A vertical multiple page exporter for Scheduler's vertical mode. Used by the
 * {@link Scheduler.feature.export.PdfExport} feature to export to multiple pages. Content will be scaled in a
 * horizontal direction to fit the page.
 *
 * You do not need to use this class directly.
 *
 * ### Extending exporter
 *
 * ```javascript
 * class MyMultiPageVerticalExporter extends MultiPageVerticalExporterVertical {
 *     // type is required for exporter
 *     static type = 'mymultipageverticalexporter';
 *
 *     get stylesheets() {
 *         const stylesheets = super.stylesheets;
 *
 *         stylesheets.forEach(styleNodeOrLinkTag => doSmth(styleNodeOrLinkTag))
 *
 *         return stylesheets;
 *     }
 * }
 *
 * const scheduler = new Scheduler({
 *     features : {
 *         pdfExport : {
 *             // this export feature is configured with only one exporter
 *             exporters : [MyMultiPageVerticalExporter]
 *         }
 *     }
 * });
 *
 * // run export with the new exporter
 * scheduler.features.pdfExport.export({ exporter : 'mymultipageverticalexporter' });
 * ```
 *
 * @classtype multipagevertical
 * @extends Scheduler/feature/export/verticalexporter/VerticalExporter
 */
export default class MultiPageVerticalExporterVertical extends SchedulerExporterMixin(VerticalExporter) {
    static $name = 'MultiPageVerticalExporterVertical';
    static type = 'verticalmultipagevertical';
    static get title() {
        // In case locale is missing exporter is still distinguishable
        return this.localize('L{MultiPageVerticalExporter.multipagevertical}');
    }
    calculateScale() {
        const { exportMeta } = this;
        return exportMeta.scale = this.getScaleValue(exportMeta.pageWidth, exportMeta.totalWidth);
    }
    estimateTotalPages(config) {
        const
            me                    = this,
            { exportMeta }        = me,
            {
                client,
                headerTpl,
                footerTpl,
                repeatHeader,
                rangeStart,
                rangeEnd
            }                     = config,
            { pageHeight }        = exportMeta,
            { timeAxisViewModel } = client,
            timeRangeDistance     = timeAxisViewModel.getDistanceBetweenDates(rangeStart, rangeEnd),
            // Ideally this should always be a positive integer number
            msPerPixel            = (rangeEnd - rangeStart) / timeRangeDistance;
        const
            totalWidth = exportMeta.totalWidth = client.timeAxisColumn.resourceColumns.totalWidth +
                client.subGrids.locked.width +
                client.subGrids.locked.splitterElement?.offsetWidth || 0,
            scale      = me.calculateScale();
        // To estimate amount of pages correctly we need to know height of the header/footer on every page
        // We will be scaling content horizontally, need to adjust content height accordingly
        let totalHeight   = exportMeta.totalHeight = timeRangeDistance + client.headerHeight + client.footerHeight,
            contentHeight = pageHeight / scale;
        if (headerTpl) {
            contentHeight -= me.measureElement(headerTpl({
                totalWidth,
                totalPages  : -1,
                currentPage : -1
            }), 'b-export-header');
        }
        if (footerTpl) {
            contentHeight -= me.measureElement(footerTpl({
                totalWidth,
                totalPages  : -1,
                currentPage : -1
            }), 'b-export-footer');
        }
        // If we are repeating header on every page we have smaller contentHeight
        if (repeatHeader) {
            totalHeight -= client.headerHeight;
        }
        let verticalPages = Math.ceil(totalHeight / contentHeight);
        if (repeatHeader) {
            totalHeight += client.headerHeight * verticalPages;
            verticalPages = Math.ceil(totalHeight / contentHeight);
        }
        Object.assign(exportMeta, {
            scale,
            contentHeight,
            totalWidth,
            totalHeight,
            verticalPages,
            msPerPixel,
            initialScroll   : 0,
            horizontalPages : 1,
            totalPages      : verticalPages,
            currentPage     : 0,
            firstTickTop    : 0
        });
    }
    async stateNextPage(config) {
        ++this.exportMeta.currentPage;
        ++this.exportMeta.verticalPosition;
        await super.stateNextPage(config);
    }
    async prepareComponent(config) {
        await super.prepareComponent(config);
        this.estimateTotalPages(config);
        this.exportMeta.pageRangeStart = config.rangeStart;
        this.exportMeta.exactGridHeight = this.exportMeta.totalHeight;
    }
    async renderRows(config) {
        const
            me             = this,
            { exportMeta } = me,
            { client }     = config,
            {
                rangeEnd,
                repeatHeader
            }              = config,
            {
                currentPage,
                contentHeight,
                msPerPixel,
                pageRangeStart
            }              = exportMeta;
        let remainingHeight = contentHeight;
        if (currentPage === 0 || repeatHeader) {
            remainingHeight -= client.headerHeight;
        }
        // duration on the time axis which can be rendered on the page
        const currentPageDuration = remainingHeight * msPerPixel;
        exportMeta.firstTickTop = client.timeAxisViewModel.getPositionFromDate(pageRangeStart);
        exportMeta.pageRangeEnd = DateHelper.min(new Date(pageRangeStart.getTime() + currentPageDuration), rangeEnd);
        await me.onRowsCollected([], config);
        if (exportMeta.pageRangeEnd.getTime() < rangeEnd.getTime()) {
            // Find start date of the time axis tick which contains current page range end. We want to align top of the
            // time axis with top of the page
            const tickIndex = Math.floor(client.timeAxis.getTickFromDate(exportMeta.pageRangeEnd, true));
            exportMeta.pageRangeStart = client.timeAxis.getAt(tickIndex).startDate;
        }
    }
    getCurrentPageDateRange() {
        return {
            pageRangeStart : this.exportMeta.pageRangeStart,
            pageRangeEnd   : this.exportMeta.pageRangeEnd
        };
    }
    async buildPage(config) {
        const
            me             = this,
            { exportMeta } = me,
            {
                headerTpl,
                footerTpl
            }              = config,
            {
                totalWidth,
                totalPages,
                currentPage,
                subGrids
            }              = exportMeta;
        // Rows are stored in shared state object, need to clean it before exporting next page
        Object.values(subGrids).forEach(subGrid => subGrid.rows = []);
        let header, footer;
        // Measure header and footer height
        if (headerTpl) {
            header = me.prepareHTML(headerTpl({
                totalWidth,
                totalPages,
                currentPage
            }));
        }
        if (footerTpl) {
            footer = me.prepareHTML(footerTpl({
                totalWidth,
                totalPages,
                currentPage
            }));
        }
        await me.renderRows(config);
        const html = me.buildPageHtml(config);
        return { html, header, footer };
    }
}
// HACK: terser/obfuscator doesn't yet support async generators, when processing code it converts async generator to regular async
// function.
MultiPageVerticalExporterVertical.prototype.pagesExtractor = async function * pagesExtractor(config) {
    const
        me      = this,
        {
            exportMeta,
            stylesheets
        }       = me,
        {
            totalPages,
            totalWidth,
            paperWidth,
            paperHeight,
            realPaperWidth,
            realPaperHeight,
            title
        }       = exportMeta,
        isPrint = config.useBrowserPrint;
    let currentPage, style;
    while ((currentPage = exportMeta.currentPage) < totalPages) {
        me.trigger('exportStep', {
            text     : MultiPageVerticalExporter.L(MultiPageVerticalExporter.exportingPageText, { currentPage, totalPages }),
            progress : Math.round(((currentPage + 1) / totalPages) * 90)
        });
        const
            { html, header, footer } = await me.buildPage(config),
            {
                scale,
                firstTickTop
            }                        = me.exportMeta;
        style = `
            .b-page-${currentPage} .b-grid-vertical-scroller {
                margin-top: -${firstTickTop}px
            }
            .b-page-${currentPage} #${config.client.id} {
                height: ${exportMeta.exactGridHeight}px !important;
                width: ${totalWidth}px !important;
            }
            ${
            isPrint
                ? `
                        .b-page-wrap {
                            width: ${realPaperWidth}in;
                            height: ${realPaperHeight}in;
                        }
                        .b-print:not(.b-firefox) .b-export-content {
                            zoom: ${scale};
                            height: 100%;
                        }
                        .b-print.b-firefox .b-export-content {
                            transform: scale(${scale});
                            transform-origin: top left;
                            height: ${100 / scale}%;
                            width: ${100 / scale}%;
                        }
                    `
                : `
                        .b-export .b-page-${currentPage}.b-export-content {
                            transform: scale(${scale});
                            transform-origin: top left;
                            height: ${100 / scale}%;
                            width: ${100 / scale}%;
                        }
                    `
        }
        `;
        if (config.repeatHeader) {
            style = `
                ${style}
                .b-export-body {
                    height: 100%;
                    display: flex;
                }
                .b-export-viewport {
                    height: 100%;
                }
            `;
        }
        else {
            style = `
                ${style}
                ${currentPage === 0 ? '' : `.b-page-${currentPage} header.b-grid-header-container {
                    display: none;
                }`}
                .b-export-body {
                    overflow: hidden;
                }
            `;
        }
        // TotalHeight might change in case of variable row heights
        // Move exported content in the visible frame
        const styles = [
            ...stylesheets,
            `<style>${style}</style>`
        ];
        await me.stateNextPage(config);
        yield {
            html : me.pageTpl({
                html,
                title,
                header,
                footer,
                styles,
                paperWidth,
                paperHeight,
                realPaperWidth,
                realPaperHeight,
                currentPage,
                isPrint
            })
        };
    }
};
MultiPageVerticalExporterVertical._$name = 'MultiPageVerticalExporterVertical';