import Base from '../../Base.js';
import ArrayHelper from '../../helper/ArrayHelper.js';
import EventHelper from '../../helper/EventHelper.js';
import ObjectHelper from '../../helper/ObjectHelper.js';
/**
 * @module Core/widget/mixin/KeyMap
 */
/**
 * Mapped key configuration
 * @typedef {String|Number|Function|Object<String,String|Number|Function>|null} KeyMapConfig
 */
/**
 * Mixin for widgets that allows for standardized and customizable keyboard shortcuts functionality. Can be configured
 * on any widget or compatible feature.
 *
 * ```javascript
 * const grid = new Grid({
 *     keyMap: {
 *         // Changing keyboard navigation to respond to WASD keys.
 *         w : 'navigateUp',
 *         a : 'navigateLeft',
 *         s : 'navigateDown',
 *         d : 'navigateRight',
 *
 *         // Removes mappings for arrow keys.
 *         ArrowUp    : null,
 *         ArrowLeft  : null,
 *         ArrowDown  : null,
 *         ArrowRight : null
 *     }
 * });
 * ```
 *
 * The invoking `KeyboardEvent` is passed as the first argument into all handlers.
 *
 * The owning Widget of the KeyMap is injected into the passed `KeyboardEvent` in the `widget` property.
 *
 * For more information on how to customize keyboard shortcuts, please see our guide (Guides/Customization/Keyboard
 * shortcuts)
 * @mixin
 */
export default Target => class KeyMap extends (Target || Base) {
    static $name = 'KeyMap';
    static configurable = {
        /**
         * An object whose keys are the [key](https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key) name
         * and optional modifier prefixes: `'Ctrl+'`, `'Alt+'`, `'Meta+'`, and `'Shift+'` (case-insensitive). The values
         * are the name of the instance method to call when the keystroke is received.
         *
         * For example:
         *
         * ```javascript
         *  keyMap : {
         *      'Ctrl+A': 'onSelectAll',
         *      'Ctrl+Z': 'onUndo'
         *  }
         * ```
         *
         * In addition to key names, the special key `'delegate'` can be included to specify additional objects that
         * have their own `keyMap`. If a keystroke is not handled by this `keyMap`, the delegates are processed until
         * one has a matching entry in its `keyMap`. The value of the `delegate` key can be a single string, an array of
         * strings or an object whose {@link Core.helper.ObjectHelper#function-getTruthyKeys-static truthy keys} are
         * {@link Core.helper.ObjectHelper#function-getPath-static dot paths} identifying the delegate(s) related to
         * this instance.
         *
         * For example:
         *
         * ```javascript
         *  keyMap : {
         *      delegate : ['widgetMap.foo', 'widgetMap.bar', 'tbar']
         *  }
         * ```
         * An widget with the above `keyMap` will delegate to the child widgets named `foo` and `bar` via the widget's
         * {@link Core.widget.Container#property-widgetMap}. In addition, the `tbar` property of the widget will also
         * be considered as a delegate.
         *
         * The following is equivalent to the above `delegate` but using an object. This form can be used to allow
         * removal of entries by derived classes or an overriding instance config.
         *
         * ```javascript
         *  keyMap : {
         *      delegate : {
         *          'widgetMap.foo' : true,
         *          'widgetMap.bar' : true,
         *          tbar            : true
         *      }
         *  }
         * ```
         *
         * @prp {Object<String,KeyMapConfig>}
         */
        keyMap : {
            value : null,
            $config : {
                merge   : 'objects',
                nullify : true
            }
        }
    };
    get widgetClass() {}
    /**
     * Override to attach the keyMap keydown event listener to something else than this.element
     * @private
     */
    get keyMapElement() {
        return this.element;
    }
    /**
     * Override to make keyMap resolve subcomponent actions to something else than this.features.
     * @private
     */
    get keyMapSubComponents() {
        return this.features;
    }
    /**
     * Returns the `keyMap` property name which matches the passed KeyboardEvent if any.
     * @param {KeyboardEvent} keyEvent
     * @param {Object} [keyMap=this.keyMap]
     * @returns {String} the key into the `keyMap` matched by the passed KeyboardEvent
     * @internal
     */
    matchKeyMapEntry(keyEvent, keyMap = this.keyMap) {
        if (keyMap && !keyEvent.handled && keyEvent.key !== undefined) {
            // Match a defined key combination, such as `Ctrl + Enter`
            // `Ctrl++` needs to be handled as a special case as split('+') will eliminate it.
            return ObjectHelper.keys(keyMap).find(keyString => {
                const
                    { altKey, ctrlKey, metaKey, shiftKey } = keyEvent,
                    keys                                   = keyString.toLowerCase().split('+'),
                    last                                   = keyString.endsWith('+') ? '+' : keys.pop(),  // only modifiers remain in keys[]
                    actualKey                              = (last === 'space') ? ' ' : last,
                    requireAlt                             = keys.includes('alt'),
                    requireCtrl                            = keys.includes('ctrl'),
                    requireMeta                            = keys.includes('meta'),
                    requireShift                           = keys.includes('shift');
                // Modifiers in any order before the actual key
                return actualKey === keyEvent.key.toLowerCase() &&
                    (requireAlt ? altKey : !altKey) &&
                    (requireCtrl ? ctrlKey : !ctrlKey) &&
                    (requireMeta ? metaKey : !metaKey) &&
                    (requireShift ? shiftKey : !shiftKey);
            });
        }
    }
    /**
     * Called on keyMapElement keyDown
     * @private
     */
    performKeyMapAction(event) {
        const
            me         = this,
            { keyMap } = me;
        let actionHandled = false,
            action, candidate, delegate, preventDefault;
        // We ignore if event is marked as handled
        if (keyMap && !event.handled && event.key !== undefined) {
            const key = me.matchKeyMapEntry(event);
            // Is there an action (fn to call) for that key combination
            if (keyMap[key]) {
                // Internally, action can be an array of actions in case of key conflicts
                const actions = ArrayHelper.asArray(keyMap[key]);
                // Let actions know it's keyMap that's calling and who owns it
                event.fromKeyMap = true;
                event.widget = this;
                // The actions will be called in the order they were added to the array.
                for (action of actions) {
                    preventDefault = true;
                    // Support for providing a config object as handler function to prevent event.preventDefault
                    if (ObjectHelper.isObject(action)) {
                        if (!action.handler) {
                            continue;
                        }
                        if (action.preventDefault === false) {
                            preventDefault = false;
                        }
                        action = action.handler;
                    }
                    if (typeof action === 'string') {
                        const {
                            thisObj,
                            handler
                        } = me.resolveKeyMapAction(action);
                        // Check if action is available, for example widget is enabled
                        if (thisObj.isActionAvailable?.({ key, action, event, actionName : action.split('.').pop() }) !== false) {
                            // If action function returns false, that means that it did not handle the action
                            if (handler.call(thisObj, event) !== false) {
                                actionHandled = true;
                                break;
                            }
                        }
                    }
                    else if (action.call(me, event) !== false) {
                        actionHandled = true;
                        break;
                    }
                }
                if (actionHandled) {
                    if (preventDefault) {
                        event.preventDefault();
                    }
                    event.handled = true;
                }
            }
            if (!actionHandled && (delegate = ObjectHelper.getTruthyKeys(keyMap.delegate))) {
                for (candidate of delegate) {
                    candidate = ObjectHelper.getPath(me, candidate);
                    actionHandled = candidate?.performKeyMapAction?.(event);
                    if (actionHandled) {
                        break;
                    }
                }
            }
        }
        return actionHandled;
    }
    /**
     * Resolves correct `this` and handler function.
     * If subComponent (action includes a dot) it will resolve in keyMapSubComponents (defaults to this.features).
     *
     * For example, in feature configurable:
     * `keyMap: {
     *     ArrowUp: 'navigateUp'
     * }`
     *
     * Will be translated (by InstancePlugin) to:
     * `keyMap: {
     *     ArrowUp: 'featureName.navigateUp'
     * }
     *
     * And resolved to correct function path here.
     *
     * Override to change action function mapping.
     * @private
     */
    resolveKeyMapAction(action) {
        const
            me                      = this,
            { keyMapSubComponents } = me;
        if (action.startsWith('up.') || action.startsWith('this.')) {
            return me.resolveCallback(action);
        }
        if (keyMapSubComponents && action.includes('.')) {
            const [component, actionName] = action.split('.');
            if (component && actionName) {
                return {
                    thisObj : keyMapSubComponents[component],
                    handler : keyMapSubComponents[component][actionName]
                };
            }
        }
        return {
            thisObj : me,
            handler : me[action]
        };
    }
    updateKeyMap(keyMap) {
        const
            me   = this,
            keys = ObjectHelper.keys(keyMap);
        me.keyMapDetacher?.();
        me.keyMapDetacher = (keys.length || null) && EventHelper.on({
            element : me.keyMapElement,
            keydown : 'keyMapOnKeyDown',
            thisObj : me
        });
    }
    // Hook on to this to catch keydown before keymap does
    keyMapOnKeyDown(event) {
        this.performKeyMapAction(event);
    }
    /**
     * This function is used for merging two keyMaps with each other. It can be used for example by a Grid's feature to
     * merge the fetature's keyMap into the Grid's with the use of a subPrefix.
     * @param {Object} target - The existing keyMap.
     * @param {Object} source - The keyMap we want to merge into target.
     * @param {Object} subPrefix - If keyMap actions in source should be prefixed, the prefix should be provided here.
     * As example, the prefix * `rowCopyPaste` will give the action 'rowCopyPaste.action'.
     * @private
     */
    mergeKeyMaps(target, source, subPrefix = null) {
        const mergedKeyMap = {};
        if (target) {
            ObjectHelper.assign(mergedKeyMap, target);
        }
        for (const key in source) {
            if (!source[key]) {
                continue;
            }
            const
                existingActions = ArrayHelper.asArray(target?.[key]),
                actions         = [];
            if (existingActions?.length) {
                actions.push(...existingActions);
            }
            if (!existingActions?.some(a => {
                const handler = (a.handler ? a.handler : a);
                return typeof handler === 'string' && handler.startsWith(subPrefix + '.');
            })) {
                for (const action of ArrayHelper.asArray(source[key])) {
                    // Mapping keymap actions to their corresponding feature's name, like group.toggleGroup
                    if (ObjectHelper.isObject(action) && action.handler) {
                        actions.push(ObjectHelper.assignIf({
                            handler : (subPrefix ? subPrefix + '.' : '') + action.handler
                        }, action));
                    }
                    else if (typeof action === 'function') {
                        actions.push(action);
                    }
                    else {
                        actions.push((subPrefix ? subPrefix + '.' : '') + action);
                    }
                }
                actions.sort((a, b) => {
                    // Sort on weight
                    const weight = (a.weight || 0) - (b.weight || 0);
                    // Then put new actions before old
                    if (weight === 0 && existingActions?.length) {
                        return existingActions.indexOf(a) - existingActions.indexOf(b);
                    }
                    return weight;
                });
            }
            mergedKeyMap[key] = actions;
        }
        return mergedKeyMap;
    }
};
