import GridGroupSummary from '../../Grid/feature/GroupSummary.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import Tooltip from '../../Core/widget/Tooltip.js';
// Actions that trigger rerendering of group summary rows
const
    eventStoreRefreshActions      = {
        update : 1,
        filter : 1
    },
    assignmentStoreRefreshActions = {
        add       : 1,
        remove    : 1,
        update    : 1,
        removeAll : 1,
        filter    : 1
    };
/**
 * @module Scheduler/feature/GroupSummary
 */
/**
 * Represents a group summary value and label
 *
 * @typedef {Object} GroupSummaryValue
 * @property {String} label The label config from a summary cell
 * @property {*} value The value returned from the summary cell renderer.
 */
// noinspection JSClosureCompilerSyntax
/**
 * A special version of the Grid GroupSummary feature that enables summaries within scheduler. To use a single summary
 * it is easiest to configure {@link #config-renderer}, for multiple summaries see {@link #config-summaries}.
 *
 * This feature is <strong>disabled</strong> by default. It is **not** supported in vertical mode.
 *
 * {@inlineexample Scheduler/feature/GroupSummary.js}
 *
 * @demo Scheduler/groupsummary
 *
 * @extends Grid/feature/GroupSummary
 * @classtype groupSummary
 * @feature
 *
 * @typings Grid.feature.GroupSummary -> Grid.feature.GridGroupSummary
 */
export default class GroupSummary extends GridGroupSummary {
    //region Config
    static $name = 'GroupSummary';
    static configurable = {
        /**
         * Show tooltip containing summary values and labels
         * @config {Boolean}
         * @default
         */
        showTooltip : true,
        /**
         * Array of summary configs which consists of a label and a {@link #config-renderer} function
         * ```
         *  summaries : [
         *      {
         *         label : 'Label',
         *         renderer : ({ startDate, endDate, eventStore, resourceStore, events, resources, groupRecord, groupField, groupValue }) => {
         *             // return display value
         *             returns '<div>Renderer output</div>';
         *         }
         *      }
         *  ]
         * ```
         * @prp {SchedulerSummaryOptions[]}
         */
        summaries : null,
        /**
         * Renderer function for a single time axis tick in a group summary row.
         * Should calculate a sum and return HTML as a result.
         *
         * ```javascript
         * new Scheduler({
         *     features : {
         *         groupSummary : {
         *             renderer : ({ startDate, endDate, eventStore, resourceStore, events, resources, groupRecord, groupField, groupValue }) => {
         *                 // return display value
         *                 returns '<div>Renderer output</div>';
         *             }
         *         }
         *     }
         * });
         * ```
         *
         * @param {Object} context Rendering context object
         * @param {Date} context.startDate Tick start date
         * @param {Date} context.endDate Tick end date
         * @param {Scheduler.model.EventModel[]} context.events Events which belong to the group
         * @param {Scheduler.model.ResourceModel[]} context.resources Resources which belong to the group
         * @param {Scheduler.data.EventStore} context.eventStore Event store
         * @param {Scheduler.data.ResourceStore} context.resourceStore Resource store
         * @param {Core.data.Model} context.groupRecord Current groups header row record
         * @param {String} context.groupField Current groups field name
         * @param {String} context.groupValue Current groups value
         * @returns {String} Html content
         * @config {Function}
         */
        renderer : null
    };
    static get properties() {
        return {
            footersToUpdate : new Set()
        };
    }
    static get pluginConfig() {
        return {
            chain : ['render']
        };
    }
    //endregion
    //region Init
    construct(scheduler, config) {
        const me = this;
        if (scheduler.isVertical) {
            throw new Error('GroupSummary feature is not supported in vertical mode');
        }
        me.scheduler = scheduler;
        super.construct(scheduler, config);
        if (!me.summaries && me.renderer) {
            me.summaries = [{ renderer : me.renderer }];
        }
        if (scheduler.isSchedulerBase) {
            scheduler.eventStore.ion({
                changePreCommit : me.onEventStoreChange,
                thisObj         : me
            });
            scheduler.assignmentStore.ion({
                changePreCommit : me.onAssignmentStoreChange,
                thisObj         : me
            });
            scheduler.ion({
                timeAxisViewModelUpdate : me.onTimeAxisChange,
                thisObj                 : me
            });
            scheduler.project.ion({
                dataReady : me.onProjectDataReady,
                thisObj   : me
            });
        }
    }
    doDestroy() {
        this._tip?.destroy();
        super.doDestroy();
    }
    //endregion
    //region Events
    onTimeAxisChange() {
        this.scheduler.rowManager.forEach(row => {
            if (row.isGroupFooter) {
                row.render();
            }
        });
    }
    onEventStoreChange({ action, records, changes }) {
        // Scheduler does minimal update on event changes, it will not rerender the summary rows.
        // Need to handle that here
        if (eventStoreRefreshActions[action]) {
            const resources = new Set();
            records.forEach(eventRecord => eventRecord.resources.forEach(r => resources.add(r)));
            this.afterChange(resources);
        }
    }
    onAssignmentStoreChange({ action, records, changes }) {
        if (assignmentStoreRefreshActions[action]) {
            const resources = new Set();
            records.forEach(assignment => assignment.resource && resources.add(assignment.resource));
            // Include old resource on reassign
            if (changes?.resourceId?.oldValue != null) {
                const oldResource = this.scheduler.resourceStore.getById(changes.resourceId.oldValue);
                oldResource && resources.add(oldResource);
            }
            this.afterChange(resources);
        }
    }
    afterChange(affectedResources) {
        const { resourceStore } = this.scheduler;
        // Collect footers to update
        for (const resourceRecord of affectedResources) {
            let record = resourceRecord;
            // Resource might not match a row (out of view, filtered out etc.)
            // Move up/down until footer is found, or we run out of rows (in case footer is below the buffer)
            if (this.target === 'header') {
                while (record && !record.isGroupHeader) {
                    record = resourceStore.getPrev(record);
                }
            }
            else {
                while (record && !record.isGroupFooter) {
                    record = resourceStore.getNext(record);
                }
            }
            const row = this.scheduler.rowManager.getRowFor(record);
            if (record && row) {
                this.footersToUpdate.add(row);
            }
        }
    }
    onProjectDataReady() {
        const
            {
                footersToUpdate,
                client
            } = this;
        // Only update the UI immediately if we are visible
        if (client.isVisible) {
            // Re-render only affected footers, once
            if (footersToUpdate.size) {
                for (const footer of footersToUpdate) {
                    // Things happen async, footer might have been destroyed
                    footer.render?.();
                }
                footersToUpdate.clear();
            }
        }
        else {
            client.whenVisible('refresh', client, [true]);
        }
    }
    //endregion
    //region Render
    /**
     * Called before rendering row contents, used to reset rows no longer used as group summary rows
     * @private
     */
    onBeforeRenderRow({ row, record }) {
        if (row.isGroupFooter && !record.meta.hasOwnProperty('groupFooterFor')) {
            const timeaxisCell = row.elements.normal.querySelector('.b-sch-timeaxis-cell');
            // remove summary cells if exist
            if (timeaxisCell) {
                timeaxisCell.innerHTML = '';
            }
        }
        super.onBeforeRenderRow(...arguments);
    }
    /**
     * Called by parent class to fill timeaxis with summary contents. Generates tick "cells" and populates them with
     * summaries.
     * ```
     * <div class="b-timeaxis-group-summary">
     *     <div class="b-timeaxis-tick">
     *         <div class="b-timeaxix-summary-value">x</div>
     *         ...
     *     </div>
     *     ...
     * </div>
     * ```
     * @private
     */
    generateHtml(column, records, cls, groupRecord, groupField, groupValue) {
        if (column.type === 'timeAxis') {
            const
                me             = this,
                { scheduler }  = me,
                { eventStore } = scheduler,
                colCfg         = scheduler.timeAxisViewModel.columnConfig;
            let html = '';
            // group events by ticks info once here to avoid performance lags
            // should be inside `scheduler.isEngineReady` check to make sure all events were calculated
            // https://github.com/bryntum/support/issues/2977
            const eventsByTick = scheduler.getResourcesEventsPerTick(records, ({ event }) => {
                return event.resources.some(resource => records.includes(resource)) && (!eventStore.isFiltered || eventStore.records.includes(event));
            });
            scheduler.timeAxis.forEach((tick, idx) => {
                const
                    groupEvents = eventsByTick[idx] || [],
                    sumHtml     = me.summaries.map(config => {
                        // summary renderer used to calculate and format value
                        const value = config.renderer?.({
                            startDate     : tick.startDate,
                            endDate       : tick.endDate,
                            resourceStore : scheduler.resourceStore,
                            events        : groupEvents,
                            resources     : records,
                            eventStore,
                            groupRecord,
                            groupField,
                            groupValue
                        }) ?? '';
                        return `<div class="b-timeaxis-summary-value">${value}</div>`;
                    }).join('');
                // get width on column index from the last header config
                html += `<div class="b-timeaxis-tick" style="width: ${colCfg[colCfg.length - 1][idx].width}px">${sumHtml}</div>`;
            });
            return `<div class="b-timeaxis-group-summary">${html}</div>`;
        }
        return super.generateHtml(column, records, cls, groupRecord, groupField, groupValue);
    }
    /**
     * Overrides parents function to return correct summary count, used when sizing row
     * @private
     */
    updateSummaryHtml(cellElement, column, records) {
        const count = super.updateSummaryHtml(cellElement, column, records);
        if (column.type === 'timeAxis') {
            const result = {
                count  : 0,
                height : 0
            };
            this.summaries.forEach(config => {
                if (config.height) {
                    result.height += config.height;
                }
                else {
                    result.count++;
                }
            });
            return result;
        }
        return count;
    }
    /**
     * Generates tooltip contents for hovered summary tick
     * @private
     */
    getTipHtml({ activeTarget }) {
        const
            me                  = this,
            { client }          = me,
            groupSummaryValues  = [],
            index               = Array.from(activeTarget.parentElement.children).indexOf(activeTarget),
            tick                = client.timeAxis.getAt(index),
            groupSummaryRecord  = client.getRecordFromElement(activeTarget),
            groupRecord         = groupSummaryRecord.isGroupFooter ? groupSummaryRecord.meta.groupRecord : groupSummaryRecord,
            { eventStore }      = client,
            resources           = groupRecord.groupChildren,
            eventsByTick        = client.getResourcesEventsPerTick(resources, ({ event }) => {
                return event.resources.some(resource => resources.includes(resource)) && (!eventStore.isFiltered || eventStore.includes(event));
            });
        let tipHtml = '',
            showTip = false;
        DomHelper.forEachSelector(activeTarget, '.b-timeaxis-summary-value', (element, i) => {
            const
                label = me._labels[i],
                text  = element.innerText.trim();
            groupSummaryValues.push({ label, text });
            if (element.innerHTML) {
                showTip = true;
            }
        });
        tipHtml = me.tooltipTemplate({
            groupSummaryValues,
            startDate     : tick.startDate,
            endDate       : tick.endDate,
            resourceStore : client.resourceStore,
            events        : eventsByTick[index] || [],
            resources,
            eventStore,
            groupRecord,
            groupField    : groupRecord.meta.groupField,
            groupValue    : groupRecord.meta.groupRowFor
        });
        return showTip ? tipHtml : null;
    }
    /**
    * Renderer function for the grouping summary tooltip.
    *
    * ```javascript
    * new Scheduler({
    *     features : {
    *         groupSummary : {
    *             tooltipTemplate : ({ groupSummaryValues, startDate, endDate, eventStore, resourceStore, events, resources, groupRecord, groupField, groupValue }) => {
    *                 return '<div>My custom tooltip html</div>';
    *             }
    *         }
    *     }
    * });
    * ```
     *
     * @param {GroupSummaryValue[]} groupSummaryValues An array of object with label and value for each summary under the tooltip
     * @param {Date} startDate Tick start date
     * @param {Date} endDate Tick end date
     * @param {Scheduler.model.EventModel[]} events Events which belong to the group
     * @param {Scheduler.model.ResourceModel[]} resources Resources which belong to the group
     * @param {Scheduler.data.EventStore} eventStore Event store
     * @param {Scheduler.data.ResourceStore} resourceStore Resource store
     * @param {Core.data.Model} groupRecord Current group's header row record
     * @param {String} groupField Current group's field name
     * @param {String} groupValue Current group's value
     * @returns {String} Html content
     * @config {Function}
     */
    tooltipTemplate({ groupSummaryValues, startDate, endDate, events, resources, eventStore, resourceStore, groupRecord, groupField, groupValue }) {
        const { client }       = this;
        let tipHtml = `<header>${this.L('L{Summary.Summary for}', client.getFormattedDate(startDate))}</header>`;
        groupSummaryValues.forEach(({ label = '', text }) => {
            tipHtml += `<label>${label}</label><div class="b-timeaxis-summary-value">${text}</div>`;
        });
        return tipHtml;
    }
    /**
     * Initialize tooltip on render
     * @private
     */
    render() {
        const
            me            = this,
            { scheduler } = me;
        if (scheduler.isSchedulerBase) {
            // if any sum config has a label, init tooltip
            if (me.showTooltip && !me._tip) {
                me._tip = new Tooltip({
                    id             : `${scheduler.id}-groupsummary-tip`,
                    cls            : 'b-timeaxis-summary-tip',
                    hoverDelay     : 0,
                    hideDelay      : 0,
                    forElement     : scheduler.timeAxisSubGridElement,
                    anchorToTarget : true,
                    forSelector    : '.b-timeaxis-group-summary .b-timeaxis-tick',
                    getHtml        : me.getTipHtml.bind(me)
                });
            }
        }
    }
    //endregion
    removeSummaryElements(rowEl) {
        const summaryElement = rowEl.querySelector('.b-timeaxis-group-summary');
        summaryElement?.remove();
        super.removeSummaryElements();
    }
    hasSummary(column) {
        return super.hasSummary(column) || column.isTimeAxisColumn;
    }
    updateSummaries() {
        this._labels = this.summaries.map(config => config.label || '');
        if (!this.isConfiguring) {
            super.updateSummaries(...arguments);
        }
    }
}
// Override Grids GroupSummary with this improved version
GroupSummary._$name = 'GroupSummary'; GridFeatureManager.registerFeature(GroupSummary, false, 'Scheduler');
