import Base from '../../../Core/Base.js';
/**
 * @module Scheduler/crud/mixin/LazyLoadCrudManager
 */
const lazyDateStores = ['events', 'resourceTimeRanges', 'assignments', 'timeRanges'];
/**
 * Mixin for CrudManager that handles lazy loading.
 * @mixin
 */
export default Target => class LazyLoadCrudManager extends (Target || Base) {
    static $name = 'LazyLoadCrudManager';
    static configurable = {
        /**
         * If set to `true`, or a config object, this makes the CrudManager load records only when needed. When a record
         * or a date range that is not already loaded is requested, a load request will be made to the specified URL.
         * More more details about lazy loading, see the [guide](#Grid/guides/data/lazyloading.md)
         * @param {Object} lazyLoad Lazy load config
         * @param {Number} lazyLoad.chunkSize The number of records to be loaded before and after the requested
         * index (only affects the ResourceStore).
         * @param {DurationUnit} bufferUnit Used together with bufferAmount to calculate the start and end dates of each
         * load request. The value is added to the current visible start or end date. Defaults to the visible time span
         * length (does not affect ResourceStore).
         * @param {Number} bufferAmount See `bufferUnit`
         * @prp {Boolean|Object}
         */
        lazyLoad : false,
        /**
         * If set to `true`, this makes the CrudManager load pages of data, instead of loading the complete dataset at
         * once. The requests made to the {@link Scheduler.crud.AbstractCrudManagerMixin#config-loadUrl} will contain
         * params with info about the current dataset being requested:
         * * `page` - The resource page
         * * `pageSize` - The resource page size
         * * `startDate` - The start date of the calculated timespan
         * * `endDate` - The end date of the calculated timespan
         *
         * For more details about paging, see the [guide](#Grid/guides/data/paging.md)
         * @config {Boolean}
         */
        remotePaging : false,
        /**
         * The number of Resource records each page should contain, when using {@link #config-remotePaging}
         * @config {Number}
         */
        pageSize : 50
    };
    updateRemotePaging(remotePaging, old) {
        if (remotePaging) {
            this.lazyLoad = true;
        }
        else if (old) {
            this.lazyLoad = false;
        }
    }
    updatePageSize() {
        if (!this.isConfiguring) {
            this.load();
        }
    }
    updateLazyLoad(lazyLoad, old) {
        if (!lazyLoad && !old) {
            return;
        }
        const
            me                           = this,
            { internalLoad, crudStores } = me;
        // Activate/deactivate on each store
        crudStores.forEach(({ store }) => me.storeShouldLazyLoad(store) && me.setupLazyStore(store));
        // Override the default load
        if (lazyLoad) {
            if (!internalLoad) {
                me.internalLoad = me.load;
                me.load         = me.internalLazyLoad;
            }
        }
        // Or restore it
        else if (internalLoad) {
            me.load = internalLoad;
        }
    }
    afterAddStore(stores) {
        stores.forEach(({ store }) => this.storeShouldLazyLoad(store) && this.setupLazyStore(store));
    }
    setupPagingStore(store) {
        if (this.remotePaging) {
            const { isResourceStore } = store;
            store.remotePaging = true;
            store.syncDataOnLoad = false;
            if (isResourceStore) {
                // Silent update, not to cause a load request
                store._pageSize = this.pageSize;
            }
            else {
                store.lazyLoad = true;
                store.lazyLoad.$isCrudManager = true;
            }
            // Implement resourceStore's and eventStore's requestData functions
            if (isResourceStore || store.isEventStore) {
                // This will implement the requestData function of each store that is added to the CrudManager
                store.requestData = params => this.requestData(params, isResourceStore);
            }
            // All other store's need to have their requestData removed (from AjaxStore)
            else {
                store.requestData = null;
            }
        }
        else {
            store.remotePaging = false;
        }
    }
    setupLazyStore(store) {
        if (this.remotePaging) {
            return this.setupPagingStore(store);
        }
        let { lazyLoad } = this;
        if (lazyLoad) {
            const { isResourceStore } = store;
            // Activate lazyLoad on all stores
            // If not true, it is a config object
            if (lazyLoad !== true) {
                const {
                    chunkSize,
                    bufferUnit,
                    bufferAmount
                }        = lazyLoad;
                lazyLoad = {};
                if (isResourceStore && chunkSize) {
                    // This only affects resourceStore
                    lazyLoad.chunkSize = chunkSize;
                }
                else if (!isResourceStore) {
                    // This does not affect resourceStores
                    if (bufferUnit) {
                        lazyLoad.bufferUnit = bufferUnit;
                    }
                    if (bufferAmount) {
                        lazyLoad.bufferAmount = bufferAmount;
                    }
                }
            }
            store.lazyLoad = lazyLoad;
            store.lazyLoad.$isCrudManager = true;
            store.syncDataOnLoad = false;
            // Implement resourceStore's and eventStore's requestData functions
            if (isResourceStore || store.isEventStore) {
                // This will implement the requestData function of each store that is added to the CrudManager
                store.requestData = params => this.requestData(params, isResourceStore);
            }
            // All other store's need to have their requestData removed (from AjaxStore)
            else {
                store.requestData = null;
            }
        }
        else if (store.lazyLoad?.$isCrudManager) {
            store.lazyLoad = false;
        }
    }
    internalLazyLoad() {
        return this.resourceStore.load();
    }
    loadCrudStore(store, data, options) {
        if (this.lazyLoad && this.storeShouldLazyLoad(store)) {
            return;
        }
        return super.loadCrudStore(store, data, options);
    }
    getLazyDateStores(skipEventStore) {
        return this.crudStores.filter(store => lazyDateStores.includes(store.storeId) && (!skipEventStore || !store.store.isEventStore));
    }
    storeShouldLazyLoad(store) {
        return store.isResourceStore || this.getLazyDateStores().some(s => s.store === store);
    }
    // Currently, this function will be called from the resourceStore requesting and index or from the eventStore
    // requesting a date range.
    async requestData(params, isResourceStore) {
        // No date params means we're probably not attached to a Scheduler/Gantt. Abort.
        if (!params.startDate || !params.endDate) {
            return;
        }
        const
            me                = this,
            dateStores        = me.getLazyDateStores(!isResourceStore),
            isResourceTree    = isResourceStore && me.resourceStore.isTree,
            pending           = me._lazyRequestPromises || (me._lazyRequestPromises = []),
            options           = {
                type   : 'load',
                params,
                // First load: request all stores
                // Then: request stores which has lazyLoad support, but only include resourceStore when that is the requests origin
                stores : me.crudStores.filter(s => !me.crudLoaded || (lazyDateStores.includes(s.storeId) || (isResourceStore && s.store.isResourceStore))).map(s => s.storeId)
            },
            promise     = Promise.all(pending).then(() => me.internalLoad(options)),
            queueParams = { ...params, promise };
        // If resourceStore is a Tree, and we are loading resources, we queue on resource ids. Using something temporary
        // just to set the stores in a loading state as we don't know what resources we are loading at this point
        if (isResourceTree) {
            queueParams.startIndex = -1;
            queueParams.count = 1;
        }
        // All date stores (except EventStore if it's the trigger) should indicate that they are loading
        dateStores.forEach(({ store }) => {
            // If we're paged, and this is a resource-initiated load, clear all previously loaded data
            if (isResourceStore && me.remotePaging) {
                store.lazyLoad.clearLoaded();
            }
            store.lazyLoad.addToQueue(queueParams);
            store.lazyLoad.triggerLazyLoadStart();
        });
        pending.push(promise);
        const
            { response }    = await promise,
            { rows, total } = isResourceStore ? response.resources : response.events;
        if (isResourceTree) {
            // Now we know what resources we have loaded
            const { idField } = me.resourceStore.modelClass;
            queueParams.resourceIds = rows.map(r => r[idField]);
        }
        // Set data to all date stores and stop the loading indicators
        dateStores.forEach(({ store : { lazyLoad }, storeId }) => {
            if (isResourceTree) {
                // Remove the "fake" queue entry from above
                delete lazyLoad.loadQueue[-1];
                // Mark the returned resources as loaded
                lazyLoad.addToQueue(queueParams);
            }
            lazyLoad.addData(response[storeId]?.rows, queueParams);
            lazyLoad.triggerLazyLoadEnd();
        });
        // The non lazy loaded stores will get their data the normal way
        pending.splice(pending.indexOf(promise), 1);
        return {
            data  : rows,
            total : isResourceStore ? total : undefined
        };
    }
};
