import { Mixin } from "../../ChronoGraph/class/BetterMixin.js";
import { CalendarIntervalMixin } from "./CalendarIntervalMixin.js";
// Calendar interval model denoting unspecified interval
export class UnspecifiedTimeIntervalModel extends Mixin([CalendarIntervalMixin], (base) => {
    class UnspecifiedTimeIntervalModel extends base {
        getCalendar() {
            return this.calendar;
        }
        getMainInterval() {
            return this.mainInterval;
        }
        // NOTE: See parent class implementation for further comments
        getPrioritySortValue() {
            if (this.prioritySortValue != null)
                return this.prioritySortValue;
            return this.prioritySortValue = this.getCalendar().getDepth();
        }
    }
    UnspecifiedTimeIntervalModel.$name = 'UnspecifiedTimeIntervalModel';
    return UnspecifiedTimeIntervalModel;
}) {
}
