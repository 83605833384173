import XlsProviderBase from './XlsProviderBase.js';
const typeMap = {
    string  : String,
    number  : Number,
    date    : Date,
    boolean : Boolean,
    bool    : Boolean
};
/**
 * @module Grid/feature/experimental/xlsproviders/WriteExcelFileProvider
 */
/**
 * Provider class utilizing write-excel-file library to create xls file
 * @non-feature
 */
export default class WriteExcelFileProvider extends XlsProviderBase {
    /**
     * This function accepts data to put to Excel file and uses write-excel-file library to create xlsx file.
     * @param {Object} config
     * @param {String} config.filename Name of the exported file
     * @param {XLSCell[][]} config.rows Array of rows, each row is an array of cells
     * @param {XLSColumn[]} config.columns Array of columns
     * @param {Object} config.sheet *deprecated, to be removed in 7.0* Object with columns and data
     * @param {XLSCell[][]} config.sheet.data Array of rows, each row is an array of cells
     * @param {XLSColumn[]} config.sheet.cols Array of columns
     * @param {String} config.MIME File MIME type
     * @returns {void}
     */
    static write({ filename, columns, rows }) {
        rows.forEach(row => {
            row.forEach(cell => {
                cell.type = typeMap[cell.type] || String;
                if (cell.type === String && typeof cell.value !== 'string') {
                    cell.value = `${cell.value}`;
                }
            });
        });
        columns.map(cell => {
            delete cell.type;
            delete cell.field;
            cell.fontWeight = 'bold';
            cell.align = 'center';
        });
        globalThis.writeXlsxFile(
            [columns, ...rows],
            {
                dateFormat : 'yyyy-mm-dd',
                fileName   : filename,
                columns    : columns.map(cell => {
                    return {
                        width : Math.round((cell.width || 100) / 10)
                    };
                })
            }
        );
    }
}
WriteExcelFileProvider._$name = 'WriteExcelFileProvider';