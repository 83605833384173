import DateStoreLazyLoadPlugin from './DateStoreLazyLoadPlugin.js';
/**
 * @module Scheduler/data/plugin/PagedDateStoreLazyLoadPlugin
 */
/**
 * Plugin for Store that handles paging of stores that is dependent on the view's visible time span.
 * @plugin
 * @internal
 * @extends Scheduler/data/plugin/DateStoreLazyLoadPlugin
 */
export default class PagedDateStoreLazyLoadPlugin extends DateStoreLazyLoadPlugin {
    static $name = 'PagedDateStoreLazyLoadPlugin';
    static configurable = {
        loadFullDateRange : true
    };
    listenToResourceStore() {
        super.listenToResourceStore();
        const
            me                        = this,
            { resourceStore, client } = me;
        if (resourceStore && !client.lazyLoad?.$isCrudManager && !client.isTimeRangeStore) {
            me.$pagedResourceStoreListenersDetacher?.();
            // A paged DateStore only cares about loading if the ResourceStore is loading
            me.$pagedResourceStoreListenersDetacher = me.resourceStore.ion({
                beforeLoadPage : 'beforeResourceStoreLoad',
                thisObj        : me
            });
            // Handle autoLoad
            if (resourceStore.isLoading || resourceStore.count) {
                // Delayed so we're sure everything has finished configuring
                me.requestAnimationFrame(me.beforeResourceStoreLoad);
            }
        }
    }
    beforeResourceStoreLoad({ params = {} } = {}) {
        this.beforeLoadCall?.(params); // Fetch dates from the view
        this.clearLoaded(); // Clear everything currently loaded
        this.doLazyLoad(params);
    }
    // Override to keep page and pageSize properties
    calculateDateRange(params) {
        const range = super.calculateDateRange(...arguments);
        if (range) {
            const
                {
                    defaultPageParamName,
                    pageSizeParamName
                }                 = this.client,
                { resourceStore } = this;
            range[defaultPageParamName] = params[defaultPageParamName] ?? resourceStore.currentPage ?? 1;
            range[pageSizeParamName] = params[pageSizeParamName] ?? resourceStore.pageSize;
        }
        return range;
    }
    // Override to do nothing
    calculateResourceRange(params) {
        return params;
    }
    addToQueue(params) {
        params.startIndex = 0;
        params.count = params.pageSize;
        super.addToQueue(params);
    }
    doDestroy() {
        super.doDestroy();
        this.$pagedResourceStoreListenersDetacher?.();
    }
};
PagedDateStoreLazyLoadPlugin._$name = 'PagedDateStoreLazyLoadPlugin';