import DomSync from '../../../../Core/helper/DomSync.js';
import DomHelper from '../../../../Core/helper/DomHelper.js';
import ExporterBase from './ExporterBase.js';
/**
 * @module Grid/feature/export/exporter/Exporter
 */
/**
 * Base class for all horizontal exporters
 * @extends Grid/feature/export/exporter/ExporterBase
 */
export default class Exporter extends ExporterBase {
    cloneElement(element, target = this.element, clear = true) {
        super.cloneElement(element, target, clear);
        DomHelper.removeEachSelector(target, '.b-grid-row,.b-grid-merged-cells-container');
    }
    getVisibleRowsCount(client) {
        const
            rowManager        = client.rowManager,
            // We cannot rely on `visibleRowCount` property, it is a mere estimate
            firstVisibleIndex = rowManager.rows.indexOf(rowManager.firstVisibleRow),
            lastVisibleIndex  = rowManager.rows.indexOf(rowManager.lastVisibleRow);
        let result;
        // first index could be negative only if there are no rendered rows, in which case we return store count
        if (firstVisibleIndex !== -1) {
            // last visible index could be negative if last actually visible row doesn't reach view bottom, i.e.
            // view is not completely filled with rows
            if (lastVisibleIndex === -1) {
                result = client.store.count - firstVisibleIndex;
            }
            else {
                // for a zero-based index we need to add 1 to the result
                result = lastVisibleIndex - firstVisibleIndex + 1;
            }
        }
        else {
            result = client.store.count;
        }
        return result;
    }
    async prepareComponent(config) {
        const
            { client }     = config,
            { rowManager } = client;
        await super.prepareComponent(config);
        // Create fake row, which will be hidden by being translated offscreen.
        // We will use it to render stuff
        const fakeRow = this.exportMeta.fakeRow = rowManager.rowClass.new({
            cls : {
                [client.rowCls] : 1,
                'b-export-row'  : 1
            },
            rowManager,
            grid      : client,
            // use fake indices, they aren't really required
            index     : -10,
            dataIndex : -10,
            isFakeRow : true
        });
        client.eachSubGrid(subGrid => {
            // Call private listener to create actual DOM nodes
            subGrid.onAddRow({ rows : [fakeRow] });
            // Trick inline widgets to think they're rendered into component (in fact they are)
            // This is required to remove `Widget.outerCls` classes from the widget
            fakeRow.element.dataset.ownerCmp = client.id;
        });
    }
    async restoreComponent(config) {
        await super.restoreComponent(config);
        if (this.exportMeta.fakeRow) {
            this.exportMeta.fakeRow.destroy();
            delete this.exportMeta.fakeRow;
        }
    }
    renderMergedCells(config, fromIndex, toIndex, rows) {
        const
            me           = this,
            { client }   = config,
            { subGrids } = me.exportMeta,
            // Generate config for merged cells
            domConfigMap = client.features.mergeCells.buildMergedCellsConfig(fromIndex, toIndex, rows);
        for (const subGridName in subGrids) {
            const
                subGrid = subGrids[subGridName],
                target  = document.createElement('div');
            // Render config into temporary element
            DomSync.sync({
                targetElement : target,
                domConfig     : {
                    children : domConfigMap[subGridName].children
                }
            });
            if (target.childNodes.length) {
                // mergeCells feature uses dependency injection, so in order to get rendered cell content we
                // need to get the cell element matching the range
                target.childNodes.forEach(child => {
                    const
                        { syncId } = child.dataset,
                        range      = client.features.mergeCells.mergedRanges
                            .find(range => range.cellElement?.parentNode.dataset.syncId === syncId);
                    if (range) {
                        // Replace cell content if range is found (it might not be found if there's only one cell)
                        child.innerHTML = range.cellElement.outerHTML;
                    }
                });
                subGrid.mergedCellsHtml = [target.innerHTML];
            }
        }
    }
}
Exporter._$name = 'Exporter';