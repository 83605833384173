import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import ContextMenuBase from '../../Core/feature/base/ContextMenuBase.js';
import Objects from '../../Core/helper/util/Objects.js';
import VersionHelper from '../../Core/helper/VersionHelper.js';
/**
 * @module Scheduler/feature/DependencyMenu
 */
/**
 * A context object passed to any `processItems` method defined for a context menu feature, and the basis of events
 * fired by context menu features.
 *
 * @typedef {MenuContext} DependencyMenuContext
 * @property {Scheduler.model.DependencyModel} dependencyRecord The dependency record clicked on.
 * @readonly
 */
/**
 * Displays a context menu when right-clicking dependency lines. Items are populated by other features and/or
 * application code.
 *
 * {@inlineexample Scheduler/feature/Dependencies.js}
 *
 * ### Default dependency menu items
 *
 * Here is the list of menu items provided by the feature and populated by the other features:
 *
 * | Reference | Text   | Weight | Feature                                  | Description                                          |
 * |-----------|--------|--------|------------------------------------------|------------------------------------------------------|
 * | `edit`    | Edit   | 100    | {@link Scheduler/feature/DependencyEdit} | Edit in the dependency editor. Hidden when read-only |
 * | `delete`  | Delete | 500    | *This feature*                           | Remove dependency. Hidden when read-only             |
 *
 *
 * ### Customizing the menu items
 *
 * The menu items in the dependency menu can be customized, existing items can be changed or removed,
 * and new items can be added. This is handled using the `items` config of the feature.
 *
 * Add extra items for all dependencies:
 *
 * ```javascript
 * const scheduler = new Scheduler({
 *     features : {
 *         dependencyMenu : {
 *             items : {
 *                 extraItem : {
 *                     text : 'Extra',
 *                     icon : 'b-fa b-fa-fw b-fa-flag',
 *                     onItem({ dependencyRecord }) {
 *                         dependencyRecord.critical = true;
 *                     }
 *                 }
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * Remove existing items:
 *
 * ```javascript
 * const scheduler = new Scheduler({
 *     features : {
 *         dependencyMenu : {
 *             items : {
 *                 delete : false
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * Customize existing item:
 *
 * ```javascript
 * const scheduler = new Scheduler({
 *     features : {
 *         dependencyMenu : {
 *             items : {
 *                 delete : {
 *                     text : 'Delete link'
 *                 }
 *             },
 *             // Process items before menu is shown
 *             processItems({ dependencyRecord, items}) {
 *                  // Push an extra item
 *                  if (dependencyRecord.isCritical === 'conference') {
 *                      items.markAsCriticalItem = {
 *                          text : 'Mark as critical',
 *                          onItem({ dependencyRecord }) {
 *                              // Do cool things
 *                          }
 *                      };
 *                  }
 *
 *                  // Do not show menu for secret links
 *                  if (dependencyRecord.isSecret === 'secret') {
 *                      return false;
 *                  }
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * {@note}
 * The `processItems` implementation may be an `async` function which `awaits` a result to mutate the `items` object.
 * {/@note}
 *
 * Note that the {@link #property-menuContext} is applied to the Menu's `item` event, so your `onItem`
 * handler's single event parameter also contains the following properties:
 *
 * - **source** The {@link Scheduler.view.Scheduler} whose UI was right-clicked.
 * - **targetElement** The element right-clicked on.
 * - **dependencyRecord** The {@link Scheduler.model.DependencyModel dependency record} clicked on.
 *
 * This feature is **enabled** by default from version 7.0
 *
 * @extends Core/feature/base/ContextMenuBase
 * @demo Scheduler/dependencies
 * @classtype dependencyMenu
 * @feature
 */
export default class DependencyMenu extends ContextMenuBase {
    //region Config
    static $name = 'DependencyMenu';
    /**
     * @member {SchedulerMenuContext} menuContext
     * An informational object containing contextual information about the last activation of the context menu.
     * @readonly
     */
    static configurable = {
        /**
         * A function called before displaying the menu that allows manipulations of its items.
         * Returning `false` from this function prevents the menu being shown.
         *
         * ```javascript
         * features         : {
         *    dependencyMenu : {
         *         processItems({ items, dependencyRecord }) {
         *             // Add or hide existing items here as needed
         *             items.myAction = {
         *                 text   : 'Cool action',
         *                 icon   : 'b-fa b-fa-fw b-fa-ban',
         *                 onItem : () => console.log(`Clicked ${dependencyRecord.name}`),
         *                 weight : 1000 // Move to end
         *             };
         *
         *            if (!dependencyRecord.allowDelete) {
         *                 items.delete.hidden = true;
         *             }
         *         }
         *     }
         * },
         * ```
         *
         * @config {Function}
         * @param {Object} context An object with information about the menu being shown.
         * @param {Scheduler.feature.DependencyMenu} context.feature A reference to this feature.
         * @param {Event} context.domEvent The initiating event.
         * @param {Number[]} context.point The client `X` and `Y` position of the initiating event.
         * @param {HTMLElement} context.targetElement The target to which the menu is being applied.
         * @param {Scheduler.model.DependencyModel} context.dependencyRecord The dependency record.
         * @param {Object<String,MenuItemConfig|Boolean>} context.items An object containing the
         *   {@link Core.widget.MenuItem menu item} configs keyed by their id.
         * @returns {Boolean|null} Returning `false` from this function prevents the menu being shown.
         * @preventable
         */
        processItems : null,
        /**
         * This is a preconfigured set of items used to create the default context menu. The default options are
         * listed at the top of the page.
         *
         * To remove existing items, set corresponding keys `null`:
         *
         * ```javascript
         * const scheduler = new Scheduler({
         *     features : {
         *         dependencyMenu : {
         *             items : {
         *                 delete   : null
         *             }
         *         }
         *     }
         * });
         * ```
         *
         * See the feature config in the above example for details.
         *
         * @config {Object<String,MenuItemConfig|Boolean|null>} items
         */
        type : 'dependency'
    };
    static get pluginConfig() {
        const config = super.pluginConfig;
        config.chain.push('populateDependencyMenu');
        return config;
    }
    //endregion
    //region Events
    /**
     * This event fires on the owning Scheduler before the context menu is shown for a dependency.
     * Allows manipulation of the items to show in the same way as in `processItems`. Returning `false` from a listener
     * prevents the menu from being shown.
     * @event dependencyMenuBeforeShow
     * @on-owner
     * @preventable
     * @param {Scheduler.view.Scheduler} source
     * @param {Object<String,MenuItemConfig>} items Menu item configs
     * @param {Scheduler.model.DependencyModel} dependencyRecord Dependency record for which the menu was triggered
     * @param {HTMLElement} element
     * @param {MouseEvent} [event] Pointer event which triggered the context menu (if any)
     */
    /**
     * This event fires on the owning Scheduler when an item is selected in the context menu.
     * @event dependencyMenuItem
     * @on-owner
     * @param {Scheduler.view.Scheduler} source
     * @param {Scheduler.model.DependencyModel} dependencyRecord Dependency record for which the menu was triggered
     * @param {Core.widget.MenuItem} item The menu item
     * @param {HTMLElement} element The dependency element
     */
    /**
     * This event fires on the owning Scheduler after showing the context menu for an event
     * @event dependencyMenuShow
     * @on-owner
     * @param {Scheduler.view.Scheduler} source
     * @param {Core.widget.Menu} menu The menu
     * @param {Scheduler.model.DependencyModel} dependencyRecord Dependency record for which the menu was triggered
     * @param {HTMLElement} element The dependency element
     */
    //endregion
    getDataFromEvent(event) {
        const
            data             = super.getDataFromEvent(event),
            { dependencies } = this.client.features,
            element          = event.target.closest(`.${dependencies.baseCls}`);
        if (element) {
            const dependencyRecord = dependencies.resolveDependencyRecord(element);
            return Object.assign(data, {
                element,
                dependencyRecord
            });
        }
    }
    getTargetElementFromEvent({ target }) {
        return this.client.features.dependencies.resolveDependencyRecord(target);
    }
    shouldShowMenu(eventParams) {
        return eventParams.dependencyRecord;
    }
    populateDependencyMenu({ items, dependencyRecord }) {
        items.delete = {
            disabled : dependencyRecord.readOnly,
            hidden   : this.client.readOnly
        };
    }
    // This generates the fixed, unchanging part of the items and is only called once
    // to generate the baseItems of the feature.
    // The dynamic parts which are set by populateDependencyMenu have this merged into them.
    changeItems(items) {
        return Objects.merge({
            delete : {
                text   : 'L{DependencyEdit.Delete}',
                icon   : 'b-icon b-icon-trash',
                cls    : 'b-separator',
                weight : 500,
                onItem({ dependencyRecord }) {
                    dependencyRecord.remove();
                }
            }
        }, items);
    }
}
DependencyMenu.featureClass = '';
DependencyMenu._$name = 'DependencyMenu'; GridFeatureManager.registerFeature(DependencyMenu, VersionHelper.checkVersion('core', '7.0', '>='), ['Scheduler', 'Gantt']);
