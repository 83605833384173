/**
 * @module Grid/feature/experimental/xlsproviders/XlsProviderBase
 */
/**
 * @typedef XLSColumn
 * @property {String} field Model field name
 * @property {String} value Column header text
 * @property {String} type Column type
 * @property {Number} width Column width
 */
/**
 * @typedef XLSCell
 * @property {String} type Cell type
 * @property {*} value Cell value
 */
/**
 * Base class for xls providers. Subclasses must implement single static {@link #function-write-static} method.
 * @non-feature
 */
export default class XlsProviderBase {
    /**
     * Implement this method to write data to an xls file
     * @param {Object} config
     * @param {String} config.filename Name of the exported file
     * @param {XLSCell[][]} config.rows Array of rows, each row is an array of cells
     * @param {XLSColumn[]} config.columns Array of columns
     * @param {Object} config.sheet *deprecated, to be removed in 7.0* Object with columns and data
     * @param {XLSCell[][]} config.sheet.data Array of rows, each row is an array of cells
     * @param {XLSColumn[]} config.sheet.cols Array of columns
     * @param {String} config.MIME File MIME type
     * @returns {void}
     */
    static write(config) {
        throw new Error('XlsProviderBase#write must be implemented in a subclass');
    }
}
XlsProviderBase._$name = 'XlsProviderBase';