import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import GridLockRows from '../../Grid/feature/LockRows.js';
import Split from './Split.js';
/**
 * @module Scheduler/feature/LockRows
 */
/**
 * This feature allows resources which satisfy a certain condition to be locked at the top of the scheduler.
 *
 * By default, the condition is that a certain named field have a truthy value. The field which decides this status
 * defaults to `'fixed'`, but that is configurable using the {@link #config-fieldName} property.
 *
 * When used with {@link #config-fieldName}, the {@link Grid.feature.CellMenu} context menu appears with an extra option
 * to toggle the value of that field in the contextual record.
 *
 * For more granular control, use the {@link #config-filterFn} to decide which records should be locked.
 *
 * {@note}Please note that this feature will not work with the {@link Scheduler.feature.Split} feature.{/@note}
 *
 * {@inlineexample Scheduler/feature/LockRows.js}
 *
 * ## Caveats
 *
 * This features utilizes the {@link Scheduler.feature.Split} feature behinds the scenes to create a split view of the
 * scheduler. Each part of the view is a separate scheduler instance, which means that certain operations are limited to
 * one part of it at the time. Unsupported features:
 *
 * * Drag selection and shift + click selection.
 * * Dependencies between events in different split sections
 * * Summary feature
 * * RowReorder feature: Rows cannot be dragged between different sections
 * * TimeSelection feature
 * * PdfExport feature
 * * Export to Excel
 * * Scheduler Pro: TimeSpanHighlight feature
 * * Scheduler Pro: CalendarHighlight feature
 *
 * This feature is **disabled** by default.
 *
 * @demo Scheduler/lock-rows
 * @classtype lockRows
 * @feature
 * @extends Grid/feature/LockRows
 *
 * @typings Grid.feature.LockRows -> Grid.feature.GridLockRows
 */
export default class LockRows extends GridLockRows {
    static $name = 'LockRows';
    onInternalPaint({ firstPaint }) {
        const
            me                = this,
            { project }       = me.client,
            { resourceStore } = project;
        // columns shown, hidden or reordered
        if (firstPaint) {
            if (me.refreshSuspended) {
                me.client.resumeRefresh();
            }
            if (resourceStore.count) {
                me.lockRows();
            }
            else {
                const unListen = project.ion({
                    refresh : () => {
                        if (resourceStore.count) {
                            unListen();
                            me.lockRows();
                        }
                    }
                });
            }
        }
    }
    getClientConfig(appendTo, order, options, config) {
        const clientConfig = this.superGetClientConfig(appendTo, order, options, config);
        // Clone is partnered with the original
        clientConfig.partner = this.client;
        delete clientConfig.crudManager;
        return clientConfig;
    }
}
const
    myProto    = LockRows.prototype,
    superProto = Split.prototype;
// We need to inherit from the SchedulerSplit feature
myProto.superGetClientConfig = superProto.getClientConfig;
LockRows.configurable.relayProperties = Split.configurable.relayProperties;
LockRows._$name = 'LockRows'; GridFeatureManager.registerFeature(LockRows, false, 'Scheduler');
